import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbDate, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DatePickerComponent} from './date-picker-offer/date-picker.component';
import {UserDetailsComponent} from '../../user/user-details/user-details.component';
import {UserCarDetailsComponent} from '../../user/user-car-details/user-car-details.component';
import {InsuranceConditionsModalComponent} from './insurance-conditions-modal/insurance-conditions-modal.component';
import {StepperComponent} from '../../../core/components/stepper/stepper.component';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {POLICY_TYPES, RenewalOfferProduct} from '../../../models/casco-table.model';
import {ProgressModalComponent} from '../../../shared/components/progress-modal/progress-modal.component';
import {DecontareComponent} from '../../../shared/components/decontare/decontare.component';
import {UnpaidRateOutcomeComponent} from '../../payment/unpaid-rate-outcome/unpaid-rate-outcome.component';
import {MonthMapping, WarningTypeEnum} from '../../../models/mapping.model';
import {ReactivatePolicyComponent} from '../../user/user-policy/reactivate-policy/reactivate-policy.component';
import Utils from '../../../shared/utils/utils';
import {OfferService} from '../../../services/offers.service';
import {GetDataService} from '../../../shared/services/get-data.service';
import {UserPreviewCarDetails} from '../../../models/user-preview-car-details.model';
import {UserPreviewData} from '../../../models/user-preview-data.model';
import {OfferPreviewDataService} from '../../../services/offer-preview-data.service';
import {
  AppState,
  selectOfferDataCustomer,
  selectOfferDataVehicle,
  selectOfferData,
  selectMyPolicies,
  selectSelectedOfferCASCO,
  selectSelectedOfferMTPL,
  selectOfferListData, selectPreValidateUserDetails, selectOfferExpired
} from '../../../store';
import {Store} from '@ngrx/store';
import {DropDownDataService} from 'src/app/services/dropDown.data.service';
import {ConfirmationComponent} from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import {LocalStorageService} from 'src/app/services/local-storage.service';
import {fetchOfferData} from '../../../store/actions/offer.actions';
import {take} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {
  TriggerFunctionFromAnotherCompService
} from '../../../shared/services/trigger-function-from-another-comp.service';
import {
  ChangeBothOfferDatesComponent
} from './date-picker-offer/change-both-offer-dates/change-both-offer-dates.component';
import {MyPoliciesService} from '../../../shared/services/myPoliciesService/my-policies.service';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-marsh-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.css']
})
export class OfferComponent implements OnInit, OnDestroy {
  offer: any;
  policyTypes = POLICY_TYPES;
  date: any = {year: 0, month: '', day: 0};
  checkPoliciesError: string;
  rawDate: Date;
  type: any = POLICY_TYPES.casco; // casco or rca
  sub: Subscription;
  carPreview: UserPreviewCarDetails;
  userPreview: UserPreviewData;
  isMobile: boolean;
  warningTypeEnum: any;
  selectOfferDataCustomerSubscription: Subscription;
  selectOfferDataVehicleSubscription: Subscription;
  selectedOffer = {price: '', providerName: '', packType: ''};
  data;
  loadingOffer = true;
  dataLoaded: boolean;
  vehicleUse: any;
  checkPoliciesHasError = false;
  renewalProduct: RenewalOfferProduct = null;
  triggerCheckExpiredOfferFromAnotherCompSubscription: Subscription;
  componentName: any;
  mtplStartDate: any;
  cascoStartDate: any;
  stepperNr: any;
  allUnfilteredPolicies: any;
  offerList: any[];
  offerToken: string;
  expiredOfferWarningStatusCASCO = false;
  expiredOfferWarningStatusMTPL = false;
  selectOfferExpiredSub: Subscription;  
  isMarketingCampaignAvailable :boolean;

  constructor(private modal: NgbModal,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private store: Store<AppState>,
              private offerService: OfferService,
              private myPoliciesService: MyPoliciesService,
              private getDataService: GetDataService,
              private offerPreviewDataService: OfferPreviewDataService,
              public dataSource: DropDownDataService,
              private localStorage: LocalStorageService,
              private toastr: ToastrService,
              private triggerFunctionFromAnotherCompService: TriggerFunctionFromAnotherCompService
  ) {

    this.isMobile = Utils.checkIfMobile();
    this.warningTypeEnum = WarningTypeEnum;
    this.carPreview = this.offerPreviewDataService.getConfigCarPreview();
    this.userPreview = this.offerPreviewDataService.getConfigUserPreview();
    this.triggerCheckExpiredOfferFromAnotherCompSubscription =
      this.triggerFunctionFromAnotherCompService.getClickEventCheckExpiredOffer().subscribe(() => {
        this.getExpiredOfferWarningStatus();
      });
  }


  ngOnInit() {
    setTimeout(() => {
      this.loadingOffer = false;
    }, 7000 );
    this.store.select(selectPreValidateUserDetails).subscribe(resPreValidateUserData => {
      if (resPreValidateUserData !== null) {

      }
    });
    this.sub = this.activatedRoute
      .queryParams
      .subscribe(params => {
        if (params.type) {
          this.type = params.type;
          if (this.offer) {
            this.getDateByPolicyType(this.offer);
          }
        }
        if (!!params['offer-token']) {
          this.store.dispatch(fetchOfferData());
          this.offerToken = params['offer-token'];
        }
      });
    setTimeout(() => {
      this.getOfferPreviewData();
    }, 2000);
  }

  getExpiredOfferWarningStatus() {
   this.store.select(selectOfferExpired).subscribe( status => {
      this.expiredOfferWarningStatusCASCO = status.casco;
      this.expiredOfferWarningStatusMTPL = status.mtpl;
    });
  }

  dateStart() {
    if (this.type === 'casco' && this.offer) {
      return this.getStartDateType(this.offer.startCascoDate);
    } else if (this.type === 'rca' && this.offer){
      return this.getStartDateType(this.offer.startMtplDate);
    }
  }

  getStartDate(date?: string) {
    if (date) {
      const getDate = string => (([year, month, day]) => ({
        year,
        month: MonthMapping[month - 1],
        day
      }))(string.split('-'));
      this.date = getDate(date);
      this.rawDate = new Date(date);
    }
  }

  getStartDateType(date?: string) {
    if (date) {
      const getDate = string => (([year, month, day]) => ({
        year,
        month: MonthMapping[month - 1],
        day
      }))(string.split('-'));
      const dateConverted = getDate(date);
      return dateConverted;
    }
  }

  checkPoliciesIfFromToken(offerChassis) {
    const customerId = window.sessionStorage.getItem('customerId');
    this.myPoliciesService.getAllPoliciesByCustomerId(customerId).subscribe(allUnfilteredPolicies => {
      if (allUnfilteredPolicies && this.offerToken) {
        const allUnfilteredPoliciesArray = allUnfilteredPolicies.data;
        if (allUnfilteredPoliciesArray && allUnfilteredPolicies.count > 0) {
          this.allUnfilteredPolicies = allUnfilteredPoliciesArray;
          const allPoliciesByChassisNumber = this.allUnfilteredPolicies.filter(policy => policy.vehicle.chassis === offerChassis);
          if (allPoliciesByChassisNumber.length > 0) {
            allPoliciesByChassisNumber.forEach(policy => {
              if (policy.product.type.code === 'MTPL') {
                const type = 'casco';
                this.router.navigate(['/offer'], {queryParams: {type}});
                window.sessionStorage.setItem('policyMTPL', 'false');
                window.sessionStorage.setItem('policyCASCO', 'true');
                sessionStorage.setItem('justMtplButtonPressed', 'true');
                sessionStorage.setItem('showToast', 'true');
              } else {
                const type = 'rca';
                this.router.navigate(['/offer'], {queryParams: {type}});
                window.sessionStorage.setItem('policyMTPL', 'true');
                window.sessionStorage.setItem('policyCASCO', 'false');
                sessionStorage.setItem('justMtplButtonPressed', 'false');
                sessionStorage.setItem('showToast', 'true');
              }
            });
          } else {
            const type = 'casco';
            this.router.navigate(['/offer'], {queryParams: {type}});
            window.sessionStorage.setItem('policyMTPL', 'true');
            window.sessionStorage.setItem('policyCASCO', 'true');
            sessionStorage.setItem('justMtplButtonPressed', 'false');
            sessionStorage.setItem('showToast', 'true');
          }
        }
      }
    });
  }

  getOfferPreviewData() {
    this.store.select(selectOfferListData)
      .subscribe(offers => {
        this.offerList = offers;
        if (this.offerToken) {
          offers.forEach(offer => {
            if (this.offerToken === offer.uuid) {
              window.sessionStorage.setItem('offerId', offer.id);
            }
          });
        }
      });
    this.store.select(selectOfferData).pipe(take(1)).subscribe(
      offerData => {
        if (offerData && Object.keys(offerData).length > 1) {
          this.offer = offerData;
          this.mtplStartDate = this.getStartDateType(this.offer.startMtplDateSource);
          this.cascoStartDate = this.getStartDateType(this.offer.startCascoDateSource);
          this.getDateByPolicyType(offerData);          
          this.checkForMarketingCampaign(this.offer);
        } else {
          const offerId = parseInt(sessionStorage.getItem('offerId'), 10);
          this.getDataService.getOfferById(offerId);
          this.store.select(selectOfferData).subscribe(
            offer => {
              if (offer && Object.keys(offer).length > 1) {
                const offerVehicleChassis = offer.vehicle.chassis;
                this.checkPoliciesIfFromToken(offerVehicleChassis);
                this.getDateByPolicyType(offer);
                this.getVehicleData(offer);
                this.mtplStartDate = this.getStartDateType(offer.startMtplDateSource);
                this.cascoStartDate = this.getStartDateType(offer.startCascoDateSource);                
                this.checkForMarketingCampaign(offer);
              }
            });
        }
      }
    );
    this.getRenewalProduct();
    this.selectOfferDataCustomerSubscription = this.store.select(selectOfferDataCustomer).subscribe(
      userData => {
        if (userData && Object.keys(userData).length > 1) {
          this.userPreview.firstName = userData[`firstName`];
          this.userPreview.lastName = userData[`lastName`];
          this.userPreview.city = userData[`city`][`name`];
          this.userPreview.county = userData[`county`][`name`];
        }
      }
    );
  }

  translatedVehicleUse(vehicleUse: string, vehicleUseId: number) {
    this.dataSource
      .fetchData('vehicleUse', '', null, 50, null)
      .subscribe((data) => {
        this.vehicleUse = data;
        this.carPreview.use = this.getUseById(vehicleUseId);
      });
  }

  getUseById(id: number) {
    if (this.vehicleUse.filter(a => a.value === id)[0]) {
      return this.vehicleUse.filter(a => a.value === id)[0].text;
    }
  }

  getVehicleData(offer: any) {
    this.selectOfferDataVehicleSubscription = this.store.select(selectOfferDataVehicle).subscribe(
      vehicleData => {
        if (vehicleData && Object.keys(vehicleData).length > 1) {
          if (offer && Object.keys(offer).length > 1) {
            this.carPreview.model = vehicleData[`model`][`name`];
            this.carPreview.make = vehicleData[`make`][`name`];
            this.translatedVehicleUse(vehicleData[`vehicleUse`][`name`], vehicleData[`vehicleUse`][`id`]);
            this.carPreview.chassis = vehicleData[`chassis`];
            this.carPreview.registrationNumber = vehicleData[`registrationNumber`];
            this.carPreview.status = vehicleData[`status`][`name`];
          }
        }
      }
    );
  }

  getDateByPolicyType(offer: any) {
    if (this.type === POLICY_TYPES.casco || !this.type) {
      if (offer[`startCascoDateSource`]) {
        this.getStartDate(offer[`startCascoDateSource`]);
      } else if (offer[`startCascoDate`]) {
        this.getStartDate(offer[`startCascoDate`]);
      } else {
        const today = new Date();
        const formattedToday = (today.getUTCFullYear() + '-' + (today.getUTCMonth() + 1) + '-' + today.getUTCDate());
        this.getStartDate(formattedToday);
      }
    } else if (this.type === POLICY_TYPES.rca) {
      if (offer[`startMtplDateSource`]) {
        this.getStartDate(offer[`startMtplDateSource`]);
      } else if (offer[`startMtplDate`]) {
        this.getStartDate(offer[`startMtplDate`]);
      } else {
        const today = new Date();
        const formattedToday = (today.getUTCFullYear() + '-' + (today.getUTCMonth() + 1) + '-' + today.getUTCDate());
        this.getStartDate(formattedToday);
      }
    }
    this.getVehicleData(offer);
  }

  ngOnDestroy(): void {
    if (this.selectOfferDataCustomerSubscription) {
      this.selectOfferDataCustomerSubscription.unsubscribe();
    }
    if (this.selectOfferDataVehicleSubscription) {
      this.selectOfferDataVehicleSubscription.unsubscribe();
    }
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  addItem(data) {
    this.selectedOffer = data;
  }

  openInsuranceConditionsModal() {
    const modalRef = this.modal.open(InsuranceConditionsModalComponent as Component, {
      size: 'lg', centered: true
    });
    modalRef.result.then((result) => {
      if (result) {
      }
    });
  }

  openReactivarePolita() {
    this.modal.open(ReactivatePolicyComponent as Component, {
      size: 'lg', centered: true
    });
  }

  openDatepickerModal() {
    if (this.showPreconfigurationWarning() === true) {
      return;
    }
    if (!this.renewalProduct) {
      const modalRef = this.modal.open(DatePickerComponent as Component, {
        centered: true, size: 'lg', backdrop: 'static'
      });
      if (this.rawDate) {
        modalRef.componentInstance.model =
          new NgbDate(this.rawDate.getUTCFullYear(),
            this.rawDate.getUTCMonth() + 1,
            this.rawDate.getUTCDate());
        modalRef.componentInstance.offer = this.offer;
        modalRef.componentInstance.vehicleChassis = this.carPreview.chassis;
        modalRef.componentInstance.componentName = this.type;
        modalRef.componentInstance.vehicleStatus = this.carPreview.status;
      }
      modalRef.result.then((result) => {
        if (result) {
          this.date = result;
          const newStartDate = (result.year + '-' + result.month + '-' + result.day).toString();
          if (newStartDate) {
            this.getNewOffer(newStartDate, 'dateChange');

          }

          this.date.month = MonthMapping[result.month - 1];
        }
      });
    }
  }

  openModalProfileDetails() {
    const modalRef = this.modal.open(UserDetailsComponent as Component, {
      size: 'lg', centered: true, backdrop: 'static'
    });
    modalRef.componentInstance.modal = true;
    modalRef.componentInstance.offer = this.offer;
    modalRef.componentInstance.casChassis = this.carPreview.chassis;
    modalRef.componentInstance.fromUserDetails = true;
    modalRef.result.then((result) => {
      if (result) {
        this.userPreview.city = result.city;
        this.userPreview.county = result.county;
        if (result.startDate) {
          this.checkPolicies(result.startDate);
          if (this.checkPoliciesHasError === true) {
            if (this.type === POLICY_TYPES.casco) {
              this.getNewOffer(this.offer.startCascoDateSource, 'profileDetails');
            } else {
              this.getNewOffer(this.offer.startMTPLDateSource, 'profileDetails');
            }
          } else {
            this.getNewOffer(result.startDate, 'profileDetails');
          }
        }
      }
    });
  }

  openProgress() {
    this.modal.open(ProgressModalComponent as Component, {
      size: 'lg', centered: true
    });
  }

  openDecontare() {
    this.modal.open(DecontareComponent as Component, {
      size: 'lg', centered: true
    });
  }

  openConsecinte() {
    this.modal.open(UnpaidRateOutcomeComponent as Component, {
      size: 'lg', centered: true
    });
  }


  openModalCarDetails() {
    const modalRef = this.modal.open(UserCarDetailsComponent as Component, {
      size: 'lg', centered: true, backdrop: 'static'
    });
    modalRef.componentInstance.modal = true;
    modalRef.componentInstance.fromCarDetail = false;
    modalRef.componentInstance.offer = this.offer;
    modalRef.result.then((result) => {
      if (result) {
        this.carPreview.use = result.use;
        if (result.startDate) {
          this.checkPolicies(result.startDate);
          if (this.checkPoliciesHasError === true) {
            if (this.type === POLICY_TYPES.casco) {
              this.getNewOffer(this.offer.startCascoDateSource, 'carDetails');
            } else {
              this.getNewOffer(this.offer.startMTPLDateSource, 'carDetails');
            }
          } else {
            this.getNewOffer(result.startDate, 'carDetails');
          }
        }
      }
    });
    // modalRef.componentInstance.vehicle = this.car;
  }

  openStepperModalCasco() {
    const modalRef = this.modal.open(StepperComponent as Component, {
      size: 'lg', centered: true
    });
    modalRef.componentInstance.selectedStep = 0;
  }

  openStepperModalRca() {
    const modalRef = this.modal.open(StepperComponent as Component, {
      size: 'lg', centered: true
    });
    modalRef.componentInstance.selectedStep = 1;
  }

  showPreconfigurationWarning(): boolean {
    if (this.renewalProduct) {
      this.store.select(selectMyPolicies).subscribe(rez => {
        if (rez && Object.keys(rez).length > 1) {
          if (rez[`CASCO`][`active`].length > 0) {
            if (this.type === POLICY_TYPES.rca && this.offer.offerForCasco === true) {
              this.store.select(selectSelectedOfferCASCO).subscribe(product => {
                if (!product || (product && product[0])) {
                  return true;
                }
              });
            }
          }
        } else {
          return false;
        }
      });
    } else {
      return false;
    }
  }

  getOfferData() {
    this.store.select(selectOfferData).pipe(take(1)).subscribe(
      offerData => {
        if (offerData && Object.keys(offerData).length > 1) {
          this.offer = offerData;
          this.getDateByPolicyType(offerData);
        } else {
          const offerId = parseInt(sessionStorage.getItem('offerId'), 10);
          this.getDataService.getOfferById(offerId);
          this.store.select(selectOfferData).subscribe(
            offer => {
              if (offer && Object.keys(offer).length > 1) {
                const offerVehicleChassis = offer.vehicle.chassis;
                this.checkPoliciesIfFromToken(offerVehicleChassis);
                this.getDateByPolicyType(offer);
                this.getVehicleData(offer);
              }
            });
        }
      }
    );
  }

  async getNewOffer(startDate?: string, formPath?: string) {
    const hasCasco = JSON.parse(sessionStorage.getItem('policyCASCO'));
    const hasMTPL = JSON.parse(sessionStorage.getItem('policyMTPL'));
    this.getOfferData();
    if (hasCasco && hasMTPL) {
      const modalRefChangeBothOfferDates = this.modal.open(ChangeBothOfferDatesComponent as Component, {
        size: 'sm', centered: true, backdrop: 'static'
      });
      modalRefChangeBothOfferDates.componentInstance.type = this.type;
      modalRefChangeBothOfferDates.componentInstance.passEntry.subscribe(async (receivedEntry) => {
        if (receivedEntry === 'both') {
          modalRefChangeBothOfferDates.close();
          const offer = {...this.offer};
          offer.id = null;
          offer.startCascoDate = (!this.type || this.type === POLICY_TYPES.casco) ? startDate : startDate;
          offer.startMtplDate = (this.type === POLICY_TYPES.rca) ? startDate : startDate;

          if (formPath === 'dateChange') {
            const modalRefProgressModal = this.modal.open(ProgressModalComponent as Component, {
              size: 'lg', centered: true, backdrop: 'static', keyboard: false,
            });
            modalRefProgressModal.componentInstance.animationDuration = environment.recalculateOfferTime;
            modalRefProgressModal.componentInstance.continuousLoading = true;
            this.offerService.addNewOffer(offer).subscribe((result: any) => {
              if (result) {
                this.getDataService.getOfferByIdWithMTPLAndCasco(result.id, modalRefProgressModal);
                this.getDataService.getOffers();
              }
            });
          } else {
            this.offerService.addNewOffer(offer).subscribe((result: any) => {
              if (result) {
                this.getDataService.getOfferByIdWithMTPLAndCasco(result.id);
                this.getDataService.getOffers();
              }
            });
          }
        } else {
          if (receivedEntry !== 'rca') {
            modalRefChangeBothOfferDates.close();
            const offer = {...this.offer};
            offer.id = null;
            offer.startMtplDate = (this.type === POLICY_TYPES.rca) ? startDate : startDate;

            if (formPath === 'dateChange') {
              const modalRefProgressModal2 = this.modal.open(ProgressModalComponent as Component, {
                size: 'lg', centered: true, backdrop: 'static', keyboard: false,
              });
              modalRefProgressModal2.componentInstance.animationDuration = environment.recalculateOfferTime;
              modalRefProgressModal2.componentInstance.continuousLoading = true;
              this.offerService.addNewOffer(offer).subscribe((result: any) => {
                if (result) {
                  this.getDataService.getOfferByIdWithMTPLAndCasco(result.id, modalRefProgressModal2);
                  this.getDataService.getOffers();
                }
              });
            } else {
              this.offerService.addNewOffer(offer).subscribe((result: any) => {
                if (result) {
                  this.getDataService.getOfferByIdWithMTPLAndCasco(result.id);
                  this.getDataService.getOffers();
                }
              });
            }
          } else {
            this.modal.dismissAll();
            const offer = {...this.offer};
            offer.id = null;
            offer.startCascoDate = (!this.type || this.type === POLICY_TYPES.casco) ? startDate : startDate;
            if (formPath === 'dateChange') {
              const modalRefProgressModal3 = this.modal.open(ProgressModalComponent as Component, {
                size: 'lg', centered: true, backdrop: 'static', keyboard: false,
              });
              modalRefProgressModal3.componentInstance.animationDuration = environment.recalculateOfferTime;
              modalRefProgressModal3.componentInstance.continuousLoading = true;
              this.offerService.addNewOffer(offer).subscribe((result: any) => {
                if (result) {
                  this.getDataService.getOfferByIdWithMTPLAndCasco(result.id, modalRefProgressModal3);
                  this.getDataService.getOffers();
                }
              });
            } else {
              this.offerService.addNewOffer(offer).subscribe((result: any) => {
                if (result) {
                  this.getDataService.getOfferByIdWithMTPLAndCasco(result.id);
                  this.getDataService.getOffers();
                }
              });
            }
          }
        }

      });
    } else if (!hasCasco && hasMTPL) {
      const offer = {...this.offer};
      offer.id = null;
      offer.startMtplDate = (this.type === POLICY_TYPES.rca) ? startDate : startDate;

      if (formPath === 'dateChange') {
        const modalRef = this.modal.open(ProgressModalComponent as Component, {
          size: 'lg', centered: true, backdrop: 'static', keyboard: false,
        });
        modalRef.componentInstance.animationDuration = environment.recalculateOfferTime;
        modalRef.componentInstance.continuousLoading = true;
        this.offerService.addNewOffer(offer).subscribe((result: any) => {
          if (result) {
            this.getDataService.getOfferByIdWithMTPLAndCasco(result.id, modalRef);
            this.getDataService.getOffers();
          }
        });
      } else {
        this.offerService.addNewOffer(offer).subscribe((result: any) => {
          if (result) {
            this.getDataService.getOfferByIdWithMTPLAndCasco(result.id);
            this.getDataService.getOffers();
          }
        });
      }
    } else if (hasCasco && !hasMTPL) {
      const offer = {...this.offer};
      offer.id = null;
      offer.startCascoDate = (this.type === POLICY_TYPES.casco) ? startDate : startDate;
      if (formPath === 'dateChange') {
        const modalRef = this.modal.open(ProgressModalComponent as Component, {
          size: 'lg', centered: true, backdrop: 'static', keyboard: false,
        });
        modalRef.componentInstance.animationDuration = environment.recalculateOfferTime;
        modalRef.componentInstance.continuousLoading = true;
        this.offerService.addNewOffer(offer).subscribe((result: any) => {
          if (result) {
            this.getDataService.getOfferByIdWithMTPLAndCasco(result.id, modalRef);
            this.getDataService.getOffers();
          }
        });
      } else {
        this.offerService.addNewOffer(offer).subscribe((result: any) => {
          if (result) {
            this.getDataService.getOfferByIdWithMTPLAndCasco(result.id);
            this.getDataService.getOffers();
          }
        });
      }
    }
  }

  async checkPolicies(startDate: string) {
    const checkPolicies = await this.offerService.checkPolicies({
      startMtplDate: (this.type === POLICY_TYPES.rca) ? startDate : this.offer.startMtplDate,
      startCascoDate: (!this.type || this.type === POLICY_TYPES.casco) ? startDate : this.offer.startCascoDate,
      chassis: this.carPreview.chassis
    });

    if (checkPolicies[`error`]) {
      if (checkPolicies[`newStartMtplDate`]) {
        this.checkPoliciesError += 'Data de inceput a politei RCA va fi inlocuita cu ' + checkPolicies[`newStartMtplDate`] + '<br>';
      }

      if (checkPolicies[`newStartCascoDate`]) {
        this.checkPoliciesError += 'Data de inceput a politei CASCO va fi inlocuita cu ' + checkPolicies[`newStartCascoDate`] + '<br><br>';
      }

      this.checkPoliciesError += '\nPentru a aplica modificarile apasati \'Confirma\'.<br> Pentru continuare cu datele introduse apasati \'Înapoi\'. <br>';

      const modalRef = this.modal.open(ConfirmationComponent as Component, {
        size: 'sm', centered: true, backdrop: 'static'
      });
      modalRef.componentInstance.text = this.checkPoliciesError;
      modalRef.result.then((result) => {
        if (result) {
          const offer = {...this.offer};
          if (this.offer[`offerForMtpl`] && checkPolicies[`newStartMtplDate`] !== null) {
            offer[`startMtplDate`] = checkPolicies[`newStartMtplDate`];
          } else if (this.offer[`offerForCasco`] && checkPolicies[`newStartCascoDate`] !== null) {
            offer[`startCascoDate`] = checkPolicies[`newStartCascoDate`];
          }
          this.offer = offer;
          this.checkPoliciesHasError = true;
        }
      });
      this.checkPoliciesError = '';
    } else {
      this.checkPoliciesHasError = false;
    }
  }

  private getRenewalProduct() {
    if (!this.type || this.type === POLICY_TYPES.casco) {
      this.store.select(selectSelectedOfferCASCO).subscribe(product => {
        if (product && product[0]) {
          this.setRenewalProduct(product);
        } else {
          const selectedOfferFromLS = this.getSelectedProductFromLocalStorage();
          if (selectedOfferFromLS) {
            this.setRenewalProduct(selectedOfferFromLS);
          }
        }
      });
    } else {
      this.store.select(selectSelectedOfferMTPL).subscribe(product => {
        if (product) {
          this.setRenewalProduct(product);
        } else {
          const selectedOfferFromLS = this.getSelectedProductFromLocalStorage();
          if (selectedOfferFromLS) {
            this.setRenewalProduct(selectedOfferFromLS);
          }
        }
      });
    }
  }

  private setRenewalProduct(product: any) {
    if (!product[0]) {
      this.removeSelectedProductFromLocalStorage();
      return;
    } else {
      this.saveSelectedProductToLocalStorage(product);
    }
    if (product[0] && product[0].renewalDueDate) {
      this.renewalProduct = product[0];
    }
  }

  private getSelectedProductFromLocalStorage(): any {
    return window.sessionStorage.getItem('selectedProduct');
  }

  private saveSelectedProductToLocalStorage(product) {
    const selectedProductToLocalStorage = JSON.stringify(product);
    sessionStorage.setItem('selectedProduct', selectedProductToLocalStorage);
  }

  private removeSelectedProductFromLocalStorage() {
    sessionStorage.removeItem('selectedProduct');
  }

  checkForMarketingCampaign(offer: any)
  {  
    this.isMarketingCampaignAvailable  = (offer.marketingCampaign != null && offer.marketingCampaign.id != null  && offer.marketingCampaign.code != null)
  }

}