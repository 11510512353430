import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {faSmile, faWindowClose} from '@fortawesome/free-solid-svg-icons';
import Utils from 'src/app/shared/utils/utils';
import {toggleTermsAndConditions} from '../../../../store/actions/offerToggle.actions';
import {AppState, selectIsCASCOSelected, selectIsMTPLSelected} from '../../../../store';
import {select, Store} from '@ngrx/store';
import {take} from 'rxjs/operators';
import {MyPoliciesService} from '../../../../shared/services/myPoliciesService/my-policies.service';
import {LocalStorageService} from '../../../../services/local-storage.service';
import {environment} from '../../../../../environments/environment';
import {OfferService} from '../../../../services/offers.service';
import {ReCaptchaV3Service} from 'ng-recaptcha';

@Component({
  selector: 'app-marsh-cr-insurance-conditions-modal',
  templateUrl: './insurance-conditions-modal.component.html',
  styleUrls: ['./insurance-conditions-modal.component.scss'],
})
export class InsuranceConditionsModalComponent implements OnInit {
  faClose = faWindowClose;
  isMobile: boolean;
  data: any;
  loading = 0;
  emailSent = false;
  terms: boolean;
  offerClickedCASCO: boolean;
  isCascoSelected = false;
  isRCASelected = false;
  pdfFileCascoRaw: ArrayBuffer = null;
  pdfFileRcaRaw: ArrayBuffer = null;
  showAccept = false;
  customerId;
  env = environment.env;
  icons = {
    smile: faSmile,
  };
  notFromOffer: boolean;
  intertitlu1: string;
  intertitlu2: string;
  intertitlu3: string;
  appType = environment.app;
  inputInsurer: string = null;
  pdfPIDFileList = {
    allianz: {
      rca: 'allianz_rca.pdf',
      casco: 'allianz_casco.pdf',
    },
    groupama: {
      rca: 'groupama_pid_rca_2.pdf',
      casco: 'groupama_pid_casco_2.pdf',
    },
    omniasig: {
      rca: 'omniasig_PID_Rca_0.pdf',
      casco: 'omniasig_PID_Casco Standard.pdf',
    },
  };
  titlesInsurer = {
    allianz: {
      intertitlu1: 'Condiţii Generale Privind Asigurarea Vehiculelor',
      intertitlu2: 'Condiţii Speciale Privind Asigurarea Vehiculelor',
      intertitlu3:
        'Clauza de Asistență Rutieră cu autovehicul de înlocuire „MOBILITY 3 PLATINUM”',
    },
    groupama: {
      intertitlu1: 'Condiții generale privind Asigurarea Vehiculelor',
      intertitlu2: 'NO',
      intertitlu3:
        'Clauza de Asistență Rutieră cu autovehicul de înlocuire „MOBILITY 3 PLATINUM”',
    },
    omniasig: {
      intertitlu1: 'Condiții generale privind Asigurarea Vehiculelor',
      intertitlu2: 'Clauze adiționale incluse',
      intertitlu3:
        'Clauza de Asistență Rutieră cu autovehicul de înlocuire „MOBILITY 3 PLATINUM”',
    },
  };
   
  captchaToken = null;
  constructor(
    private modal: NgbModal,
    private store: Store<AppState>,
    public activeModal: NgbActiveModal,
    public localStorageService: LocalStorageService,
    private offerService: OfferService,
    private recaptchaV3Service: ReCaptchaV3Service,
    
  ) {}

  ngOnInit() {

      this.recaptchaV3Service.execute('registerSubmit').subscribe(
      (tokenCaptcha) => {
        this.captchaToken = tokenCaptcha;
      },
      (error) => {
       console.log("error" + error);
      });


    this.customerId = window.sessionStorage.getItem('customerId');
    this.isMobile = Utils.checkIfMobile();
    this.store
      .pipe(select(selectIsCASCOSelected), take(1))
      .subscribe((isSelected) => {
        this.isCascoSelected = isSelected;
        if (isSelected) {
          this.getRawPdfFile(this.pdfCascoLink).then((buffer) => {
            this.pdfFileCascoRaw = buffer;
          });
        }
      });
    if (this.inputInsurer === `Allianz`) {
      this.intertitlu1 = this.titlesInsurer[`allianz`][`intertitlu1`];
      this.intertitlu2 = this.titlesInsurer[`allianz`][`intertitlu2`];
      this.intertitlu3 = this.titlesInsurer[`allianz`][`intertitlu3`];
    } else if (this.inputInsurer === `Groupama`) {
      this.intertitlu1 = this.titlesInsurer[`groupama`][`intertitlu1`];
      this.intertitlu2 = this.titlesInsurer[`groupama`][`intertitlu2`];
      this.intertitlu3 = this.titlesInsurer[`groupama`][`intertitlu3`];
    } else if (this.inputInsurer === `Omniasig`) {
      this.intertitlu1 = this.titlesInsurer[`omniasig`][`intertitlu1`];
      this.intertitlu2 = this.titlesInsurer[`omniasig`][`intertitlu2`];
      this.intertitlu3 = this.titlesInsurer[`omniasig`][`intertitlu3`];
    }
    this.store
      .pipe(select(selectIsMTPLSelected), take(1))
      .subscribe((isSelected) => {
        this.isRCASelected = isSelected;
        if (isSelected) {
          this.getRawPdfFile(this.pdfCascoLink).then((buffer) => {
            this.pdfFileRcaRaw = buffer;
          });
        }
      });
  }

  getRawPdfFile(link) {
    return new Promise<ArrayBuffer>((resolve, reject) => {
      fetch(link)
        .then((response) => {
          response.blob().then(async (content) => {
            resolve(await content.arrayBuffer());
          });
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  get offerClicked() {
    this.store.pipe(select(selectIsCASCOSelected), take(1)).subscribe((rez) => {
      if (rez === null) {
        this.offerClickedCASCO = false;
      } else {
        this.offerClickedCASCO = rez;
      }
    });
    return this.offerClickedCASCO;
  }

  setData(optionSelected) {
    this.store.dispatch(
      toggleTermsAndConditions({ toggleTermsAndConditions: optionSelected })
    );
    this.activeModal.close(optionSelected);
  }

  closeModal(optionSelected) {
    this.modal.dismissAll();
    this.store.dispatch(
      toggleTermsAndConditions({ toggleTermsAndConditions: optionSelected })
    );
    this.activeModal.close(optionSelected);
  }

  closeModalFromX() {
    this.modal.dismissAll();
    this.activeModal.close();
  }

  sendEmail(inputInsurer) {
    const insurerId = this.getInsurerId(inputInsurer);
    const body = {
      objectType: 'PID',
      objectId: insurerId,
      objectEnvironment: environment.app ?? '',
    };
    this.loading = 1;
    this.offerService.reportIncorrectData(body).subscribe((x) => {
      if (x.statusCode === 403 || x.statusCode === 404) {
        this.loading = 3;
      } else {
        this.loading = 2;
      }
    });
  }

  getInsurerId(inputInsurer: string) {
    const insurers = sessionStorage.getItem('insurers');
    for (var insurer of JSON.parse(insurers)) {
      if (insurer.name.toUpperCase() === inputInsurer.toUpperCase()) {
        return insurer.id;
      }
    }
  }

  startFileDownload() {
    // const filesNameForDownload: { [name: string]: string } = {
    //   allianz: 'Conditii_Casco_Allianz_aug_2021_pdf_downloadabil.pdf',
    //   groupama: 'Conditii_CASCO_Dacia_GroupamaA4_pdf_downloadabil.pdf',
    //   omniasig: 'Conditii_Casco_Omniasig_aug_2021_pdf_downloadabil.pdf'
    // };
    // const currentFileName = filesNameForDownload[this.inputInsurer.toLocaleLowerCase()];
    // this.downloadFiles([
    //   {
    //     path: this.pdfCascoLink,
    //     name: this.pdfCascoLink.split('/').pop()
    //   },
    //   {
    //     path: `${this.baseUrlPath}/assets/documents/${currentFileName}`,
    //     name: currentFileName
    //   }
    // ]);

    
    const body = {
      insurerCode: this.getInsurerCode(this.inputInsurer.toLocaleLowerCase()),
      objectEnvironment: environment.app ?? '',
    };
    this.loading = 1;
     this.offerService.getPID(body,this.captchaToken).subscribe((res: any) => {
      if (res.statusCode === 403 || res.statusCode === 404) {
        this.loading = 3;
      } else {
        this.loading = 2;
      }
      // console.log(JSON.stringify(res));      
      for (let i = 0; i < res.documentNames.length; i++) {
        const source = `data:application/pdf;base64,${res.documentContents[i]}`;
        const link = document.createElement('a');
        link.href = source;
        link.download = res.documentNames[i];
        link.click();
      }
    });
  }

  getInsurerCode(insurerName) {
    switch (insurerName) {
      case 'allianz':
        return 'AZT';
      case 'groupama':
        return 'GAM';
      case 'omniasig':
        return 'OMN';
    }
  }

  downloadFiles(filesForDownload: FileForDownload[]) {
    // { path: "/path/file1.txt", name: "file1.txt" }

    const temporaryDownloadLink = document.createElement('a');
    temporaryDownloadLink.style.display = 'none';
    document.body.appendChild(temporaryDownloadLink);

    filesForDownload.forEach((file) => {
      temporaryDownloadLink.setAttribute('href', file.path);
      temporaryDownloadLink.setAttribute('download', file.name);
      temporaryDownloadLink.click();
    });
    document.body.removeChild(temporaryDownloadLink);
  }

  get baseUrlPath() {
    return window.location.protocol + '//' + window.location.host;
  }

  getPdfLink(company: string, type: string) {
    return `${this.baseUrlPath}/assets/documents/PID/${this.pdfPIDFileList[company][type]}`;
  }

  get pdfCascoLink() {
    return this.getPdfLink(this.inputInsurer.toLocaleLowerCase(), 'casco');
  }

  get pdfRcaLink() {
    return this.getPdfLink(this.inputInsurer.toLocaleLowerCase(), 'rca');
  }

  pdfLoadProgress(e) {}

  pdfAfterLoad(e) {}
}

interface FileForDownload {
  path: string;
  name: string;
}
