import {Injectable} from '@angular/core';
import {throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {OktaAuthService} from '@okta/okta-angular';
import {PullDataService} from 'src/app/services/pull-data.service';
import {environment} from 'src/environments/environment';
import {ApigeeService} from './apigee.service';
import {SessionDataService} from './session-data.service';
import {AppStorageService} from './app-storage.service';
import { NbCardBodyComponent } from '@nebular/theme';


@Injectable({
  providedIn: 'root'
})

export class OfferService {
  baseUri = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private appStorageService: AppStorageService,
    private oktaAuthService: OktaAuthService,
    private apigeeService: ApigeeService,
    private sessionDataService: SessionDataService,
    private pullDataService: PullDataService
  ) {
  }

  validateStep(offerId, step) {
    const url = `${this.baseUri}/offers/validate-step/` + offerId + '/' + step;
    return this.http.post(url, {})
      .pipe(
        catchError(this.errorManagement)
      );
  }

  patchCustomer(customerId, data) {
    const url = `${this.baseUri}/online/customers/${customerId}`;
    return this.http.patch(url, data);
  }

  getCustomerByID(id) {
    const url = `${this.baseUri}/online/customers/${id}`;
    return this.http.get(url).pipe(
      catchError(this.errorManagement)
    );
  }

  saveOffer(body) {
    const url = `${this.baseUri}/offers`;
    return this.http.post(url, body)
      .pipe(
        catchError(this.errorManagement)
      );
  }

  addNewOffer(body) {
    const url = `${this.baseUri}/offers`;
    return this.http.post<any[]>(url, body).pipe(
      catchError(this.errorManagement)
    );
  }

  checkPolicies(body) {
    const url = `${this.baseUri}/offers/check-policies`;
    return this.http.post<any[]>(url, body).pipe(
      catchError(this.errorManagement)
    );
  }

  getProductsOfferList(offerID) {
    const url = `${this.baseUri}/offer-products/${offerID}?limit=100&offset=0`;
    return this.http.get<any>(url).pipe(
      catchError(this.errorManagement)
    );
  }

  getCurrentOffer() {
    const url = `${this.baseUri}/offers?limit=1&offset=0&cache=0`;
    return this.http.get<any[]>(url)
      .pipe(
        catchError(this.errorManagement)
      );
  }

  getOffers() {
    const url = `${this.baseUri}/offers?limit=100&offset=0&cache=0`;
    return this.http.get<any[]>(url)
      .pipe(
        catchError(this.errorManagement)
      );
  }

  getOfferByID(offerID) {
    const url = `${this.baseUri}/offers/full/${offerID}`;
    return this.http.get<any>(url)
      .pipe(
        catchError(this.errorManagement)
      );
  }

  getOffersByCustomerId(customerId: number) {
    const url = `${this.baseUri}/offers?limit=100&offset=0&cache=0&&filter[0]=customer.sourceCustomer.id||$eq||${customerId}&filter[1]=isActive||$eq||true`;
    return this.http.get<any[]>(url)
      .pipe(
        catchError(this.errorManagement)
      );
  }

  setProductsPull(offerID, body) {
    const url = `${this.baseUri}/offers/pull/products-set/${offerID}`;
    return this.pullDataService.postAsyncRequest(url, body).pipe(
      catchError(this.errorManagement)
    );
  }

  errorManagement(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

  reportIncorrectData(data) {
    const url = `${this.baseUri}/tools/report-incorrect-object-data`;
    return this.http.post<any>(url, data).pipe(
      catchError(this.errorManagement)
    );
  }

   getPID(data,captchaToken) {
      let headers = new HttpHeaders(
        {
          Recaptcha: captchaToken,
          'Content-Type': 'application/json',
        });
        
      const url = `${this.baseUri}/online/pid`;
      return this.http.post<any>(url, data,{headers: headers}).pipe(
        catchError(this.errorManagement)
      );
  }

  prepareCustomerForForm(customer) {
    let returnData = {
      id: null,
      type: null,
      uniqueIdentifier: null,
      firstName: null,
      lastName: null,
      county: null,
      city: null,
      email: null,
      phone: null,
      driverLicenseDate: null,
      discount: null,
      discountDescription: null,
      activityCode: null,
      streetName: null,
      streetNumber: null,
      buildingNumber: null,
      apartment: null,
    };
    if (customer) {
      returnData = Object.assign(returnData, customer);

      if (customer[`type`][`id`]) {
        returnData[`type`] = String(customer[`type`][`id`]);
      } else {
        returnData[`type`] = String(customer[`type`]);
      }

      if (customer[`county`] && customer[`county`][`id`]) {
        returnData[`county`] = customer[`county`][`id`];
      }

      if (customer[`city`] && customer[`city`][`id`]) {
        returnData[`city`] = customer[`city`][`id`];
      }

      if (customer[`driverLicenseDate`]) {
        returnData[`driverLicenseDate`] = new Date(customer[`driverLicenseDate`]);
      }

      if (customer[`discount`] && customer[`discount`][`id`]) {
        returnData[`discount`] = customer[`discount`][`id`];
      }

      if (customer[`activityCode`] && customer[`activityCode`][`id`]) {
        returnData[`activityCode`] = customer[`activityCode`][`id`];
      }
    }
    return returnData;
  }
}