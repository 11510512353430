import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { AppState, selectSelectedOfferCASCO } from 'src/app/store';
import {WarningType} from '../../../models/mapping.model';
import {WarningTypeEnum} from '../../../models/mapping.model';
import { UnpaidRateOutcomeComponent } from '../../../pages/payment/unpaid-rate-outcome/unpaid-rate-outcome.component';
import {Router} from '@angular/router';
import {WarningService} from '../../services/warning.service';
import {WarningCascoOfferDetailsComponent} from '../warning-modals/warning-casco-offer-details/warning-casco-offer-details.component';
import {WarningCascoOfferRoadsideAssistanceComponent} from '../warning-modals/warning-casco-offer-roadside-assistance/warning-casco-offer-roadside-assistance.component';
import {environment} from '../../../../environments/environment';
import {WarningRcaOfferDetailsComponent} from '../warning-modals/warning-rca-offer-details/warning-rca-offer-details.component';
import {
  WarningChangeConfigurationImplicationsComponent
} from '../warning-modals/warning-change-configuration-implications/warning-change-configuration-implications.component';

@Component({
  selector: 'app-marsh-warning',
  templateUrl: './warning.component.html',
  styleUrls: ['./warning.component.scss']
})
export class WarningComponent implements OnInit {
  appType = environment.app;

  constructor(
    private modal: NgbModal,
    public activeModal: NgbActiveModal,
    public router: Router,
    public warningService: WarningService,
    private store: Store<AppState>) {
  }

  warningTypesEnums = WarningTypeEnum;
  @Input() warningType: any;
  @Input() insurer: string;
  @Input() renewalDueDate: Date;
  @Input() dataToReport;  
  @Input() isMarketingCampaignAvailable:boolean;


  ngOnInit() {
  }

  closeModal() {
    this.activeModal.close();
  }

  consecinteNeachitareRata(){
    this.modal.open(UnpaidRateOutcomeComponent as Component, {
      size: 'lg', centered: true
    });
  }

  reportWrongData(wrongDataSpot) {
    this.warningService.sendDataToReportDataComp(wrongDataSpot, this.dataToReport);
  }

  warningCascoOfferDetails() {
    this.modal.open(WarningCascoOfferDetailsComponent as Component, {
      size: 'lg', centered: true
    });
  }

  warningRcaOfferDetails() {
    this.modal.open(WarningRcaOfferDetailsComponent as Component, {
      size: 'lg', centered: true
    });
  }

  warningCascoOfferRoadsideAssistance() {
    this.modal.open(WarningCascoOfferRoadsideAssistanceComponent as Component, {
      size: 'lg', centered: true
    });
  }

  openWarningChangeConfigurationImplications() {
    this.modal.open(WarningChangeConfigurationImplicationsComponent as Component, {
      size: 'lg', centered: true
    });
  }

  openMTPLPreConfigurationDetails() {
    this.modal.open(WarningChangeConfigurationImplicationsComponent as Component, {
      size: 'lg', centered: true
    });
  }

}
