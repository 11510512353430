import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {NgbModal, NgbTooltip} from '@ng-bootstrap/ng-bootstrap';
import {CascoMtplPackageModalComponent} from '../casco-mtpl-package-modal/casco-mtpl-package-modal..component';
import {InsuranceConditionsModalComponent} from '../insurance-conditions-modal/insurance-conditions-modal.component';
import {GetDataService} from '../../../../shared/services/get-data.service';
import {BehaviorSubject} from 'rxjs';
import {AppState, selectOfferDataMTPL, selectSelectedOfferCASCO} from '../../../../store';
import {Store} from '@ngrx/store';
import {setSelectedOfferCASCO, setSelectedOfferMTPL} from 'src/app/store/actions/selectedOffer.actions';
import {
  toggleIfSelectedOfferCASCO,
  toggleIfSelectedOfferMTPL, toggleJustCASCO,
  toggleJustMTPL, toggleTermsAndConditions
} from 'src/app/store/actions/offerToggle.actions';
import {CascoData, InstallmentData, RenewalOfferProduct} from 'src/app/models/casco-table.model';
import {first, take} from 'rxjs/operators';
import {environment} from '../../../../../environments/environment';
import {
  TriggerFunctionFromAnotherCompService
} from '../../../../shared/services/trigger-function-from-another-comp.service';
import Utils from '../../../../shared/utils/utils';
import { CampaignDetailsPopupComponent } from '../campaign-details-popup/campaign-details-popup/campaign-details-popup.component';
import { JsonPipe } from '@angular/common';
import { selectOfferDataCASCO, } from '../../../../store';
import { constructorParametersDownlevelTransform } from '@angular/compiler-cli';

@Component({
  selector: 'app-installments',
  templateUrl: './installments.component.html',
  styleUrls: ['./installments.component.scss'],
})
export class InstallmentsComponent implements OnInit, OnChanges {
  appType = environment.app;
  @Input() CASCODataInstallments: CascoData;
  @Input() activeTab: any;
  @Input() renewalProduct: RenewalOfferProduct = null;
  @ViewChild('ttCascoGold', { static: false }) mytooltipCascoGold: NgbTooltip;
  @ViewChild('ttCascoSilver', { static: false })
  mytooltipCascoSilver: NgbTooltip;

  installment: any;
  insuranceCond;
  selectedOption;
  sessionStorageMTPLObj: any = [];
  noMTPLOffer;
  noAZTMTPL;
  selectedCount: number;
  dataMTPL = [];
  isMobile;
  packageData: BehaviorSubject<any>;
  @Output() productWasSelected = new EventEmitter<any>();
  selectedProduct: any;
  productToDeselect;  
  discountPercentage:any;
  myJson:any=JSON;

  constructor(
    private modal: NgbModal,
    private triggerFunctionFromAnotherCompService: TriggerFunctionFromAnotherCompService,
    private store: Store<AppState>,
    private getDataService: GetDataService
  ) {
    this.packageData = new BehaviorSubject(null);
    this.getDataService.cancelOnRequestConsultant.subscribe((rez) => {
      if (rez === true && this.CASCODataInstallments) {
        this.resetCheckedRadioButton();
      }
    });
    this.selectedCount = 0;
    this.isMobile = Utils.checkIfMobile();
  }

  ngOnInit(): void {
    this.checkForSelectedCASCOfferInSessionStorage();
    this.checkForSelectedMTPLOffer();
  }

  get justCASCO() {
    return JSON.parse(sessionStorage.getItem('policyMTPL'));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.CASCODataInstallments) {
      this.CASCODataInstallments = changes.CASCODataInstallments.currentValue;
      if (this.CASCODataInstallments) {
        this.installment = this.getInstallmentByActiveTab(this.activeTab);
      }
    }
    // You can also use previousValue and firstChange for comparing old and new values
    if (this.CASCODataInstallments && changes.activeTab) {
      this.installment = this.getInstallmentByActiveTab(
        changes.activeTab.currentValue
      );
      this.installment.sort(
        (a, b) => parseFloat(a.cascoGold) - parseFloat(b.cascoGold)
      );
    }
    this.getSelectedProduct();
  }

  private getInstallmentByActiveTab(activeTab: number): InstallmentData[] {
    if (activeTab === 0) {
      // installment 12
      return this.CASCODataInstallments?.installment12;
    } else if (activeTab === 1) {
      // installment 4
      return this.CASCODataInstallments?.installment4;
    } else {
      // installment 1
      return this.CASCODataInstallments?.installment1;
    }
  }

  setCASCOOfferToSessionStorage(product) {
    sessionStorage.setItem('selectedCASCOOffer', JSON.stringify(product));
  }

  checkForSelectedCASCOfferInSessionStorage() {
    const selectedCASCOOffer = JSON.parse(
      sessionStorage.getItem('selectedCASCOOffer')
    );
    const selectedOfferId = JSON.parse(sessionStorage.getItem('offerId'));
    const policyCASCO = JSON.parse(sessionStorage.getItem('policyCASCO'));
    const policyMTPL = JSON.parse(sessionStorage.getItem('policyMTPL'));

    if (
      selectedCASCOOffer &&
      selectedOfferId &&
      selectedCASCOOffer['0'].offerId === selectedOfferId
    ) {
      this.productWasSelected.emit(selectedCASCOOffer);
      this.store.dispatch(
        setSelectedOfferCASCO({ offerSelectedCASCO: selectedCASCOOffer })
      );
      this.store.dispatch(
        toggleIfSelectedOfferCASCO({ toggleIfSelectedOfferCASCO: true })
      );
      this.store.dispatch(toggleJustMTPL({ toggleJustMTPL: !policyMTPL }));
      this.store.dispatch(toggleJustCASCO({ toggleJustCASCO: !policyCASCO }));
    } else {
      this.store.dispatch(setSelectedOfferCASCO({ offerSelectedCASCO: null }));
      this.store.dispatch(
        toggleIfSelectedOfferCASCO({ toggleIfSelectedOfferCASCO: null })
      );
    }
  }

  checkForSelectedMTPLOffer() {
    this.sessionStorageMTPLObj = [];
    const selectedMTPLOffer = JSON.parse(
      sessionStorage.getItem('selectedMTPLOffer')
    );
    const selectedOfferId = JSON.parse(sessionStorage.getItem('offerId'));
    const policyCASCO = JSON.parse(sessionStorage.getItem('policyCASCO'));
    const policyMTPL = JSON.parse(sessionStorage.getItem('policyMTPL'));

    if (selectedMTPLOffer && selectedMTPLOffer.offerId === selectedOfferId) {
      this.sessionStorageMTPLObj.push(selectedMTPLOffer);
      this.store.dispatch(
        setSelectedOfferMTPL({ offerSelectedMTPL: this.sessionStorageMTPLObj })
      );
      this.store.dispatch(
        toggleIfSelectedOfferMTPL({ toggleIfSelectedOfferMTPL: true })
      );
      this.store.dispatch(toggleJustMTPL({ toggleJustMTPL: !policyMTPL }));
      this.store.dispatch(toggleJustCASCO({ toggleJustCASCO: !policyCASCO }));
    } else {
      this.store.dispatch(setSelectedOfferMTPL({ offerSelectedMTPL: null }));
      this.store.dispatch(
        toggleIfSelectedOfferMTPL({ toggleIfSelectedOfferMTPL: null })
      );
    }
  }

  checkIfThereAreMTPLOffers() {
    return this.store
      .select(selectOfferDataMTPL)
      .pipe(take(1))
      .subscribe((rez) => {      
        this.dataMTPL = rez;
        this.noMTPLOffer = rez === null;
        if (this.noMTPLOffer === false) {
          this.getProductPackageDiscount();
          return true;
        } else {
          return false;
        }
      });
  }

  checkIfThereAreMTPLOffersWithInsurer(insurer: string) {   
    let val = null;
    let withSettlement = null;
    let isMtplPresent  = false;
    
    this.store
      .select(selectOfferDataMTPL)
      .pipe(take(1))
      .subscribe((rez) => {
       withSettlement = this.getResponseFromResult(rez,"12","withSettlement");       
       isMtplPresent =  this.checkIfMTPLPresent( withSettlement,insurer);
       if(!isMtplPresent)
       {
        withSettlement = this.getResponseFromResult(rez,"12","withoutSettlement");       
        isMtplPresent =  this.checkIfMTPLPresent( withSettlement,insurer);
       }

       if(!isMtplPresent)
       {
        withSettlement = this.getResponseFromResult(rez,"6","withSettlement");       
        isMtplPresent =  this.checkIfMTPLPresent( withSettlement,insurer);
       }
       if(!isMtplPresent)
       {
        withSettlement = this.getResponseFromResult(rez,"6","withoutSettlement");       
        isMtplPresent =  this.checkIfMTPLPresent( withSettlement,insurer);
       }
       if(isMtplPresent)
        {
          this.getProductPackageDiscount();
        }

        val =  isMtplPresent;
      });

      return val;
  }

  getResponseFromResult(res, installemntNo, withSettlement)
  {
    let result = null;
    try {
      result = res["MTPL"][installemntNo][withSettlement];
      
    } catch (error) {     
      console.log(JSON.stringify(error));
    }

    return result;
  }

  checkIfMTPLPresent(mtpls,insurer)
  {
    let response = false;
     if(mtpls != null)
     {
        mtpls.forEach(data => {   
          if(data["product"]["insurer"]["code"] == insurer && data["premium"] != null)
          {
            response =  true;
          }
        });
     }
     return response;
  }

  showInsurer(data) {
    let emptyFieldsCount = 0;
    if (data.insurer !== null) {
      const products = data.products;
      products.forEach((product) => {
        if (product.premium === null || product.premium === '') {
          emptyFieldsCount++;
        }
      });
      return emptyFieldsCount;
    }
  }

  isSelected(product, pack) {
    const selectedCASCOOffer = JSON.parse(
      sessionStorage.getItem('selectedCASCOOffer')
    );
    if (pack === 'P' && selectedCASCOOffer && product) {
      return (
        product &&
        product.offer.id === selectedCASCOOffer['0'].offerId &&
        product.id.toString() + pack === selectedCASCOOffer['0'].gridItemId
      );
    } else if (selectedCASCOOffer && product && pack === 'I') {
      return (
        product &&
        product.offer.id === selectedCASCOOffer['0'].offerId &&
        product.id === selectedCASCOOffer['0'].gridItemId
      );
    } else {
      return false;
    }
  }

  getSelectedProduct() {
    this.store.select(selectSelectedOfferCASCO).subscribe((cascoProduct) => {
      const installment = this.getInstallmentByActiveTab(this.activeTab);
      if (installment) {
        if (this.renewalProduct) {
          this.selectedProduct = this.renewalProduct;
          // this.sortInstallmentListByInsurer(installment, this.selectedProduct?.cascoProviderName);
        } else {
          if (cascoProduct) {
            this.selectedProduct = cascoProduct[0];
          }
        }
        installment.forEach((data) => {
          data.products.forEach((product) => {
            if (
              product.product.id === this.selectedProduct?.productId &&
              product.package.id === this.selectedProduct?.packageId &&
              product.installmentNumber.id ===
                this.selectedProduct?.installmentNumberId &&
              product.product.insurer.name ===
                this.selectedProduct?.cascoProviderName
            ) {
              product.isSelected = true;
            } else {
              product.isSelected = false;
            }
          });
        });
      }
    });
  }

  toModelSelectedCASCO(item, itemId) {
    return [
      {
        item: item.premium,
        cascoProviderName: item.product.insurer.name,
        nrOfInstallments: item.installmentNumber.code,
        packType: item.package?.name === 'no' ? 'individual' : 'package',
        packageId: item.package?.id,
        installmentNumberId: item.installmentNumber.id,
        renewalDueDate: this.selectedProduct?.renewalDueDate,
        cascoType: item.product.cascoType.name,
        offerId: item.offer.id,
        productId: item.product.id,
        gridItemId: itemId,
      },
    ];
  }

  onItemChange(item, itemId) {
    const selectedCASCO = this.toModelSelectedCASCO(item, itemId);
    this.setCASCOOfferToSessionStorage(selectedCASCO);
    this.triggerFunctionFromAnotherCompService.sendClickEventCheckSelectedCASCOOffer();
    this.store
      .select(selectSelectedOfferCASCO)
      .pipe(take(1))
      .subscribe((rez) => {
        this.productToDeselect = rez;
      });
    if (this.productToDeselect) {
      if (
        this.selectedCount === 0 &&
        this.productToDeselect[0]?.gridItemId ===
          (selectedCASCO[0]?.gridItemId).toString()
      ) {
        const input = document.getElementById(itemId) as HTMLInputElement;
        input.checked = false;
        this.selectedCount = this.selectedCount + 1;
        this.selectedProduct = null;
        sessionStorage.removeItem('selectedCASCOOffer');
        this.productWasSelected.emit(null);
      } else {
        this.selectedCount = 0;
        this.setCASCOOfferToSessionStorage(selectedCASCO);
        this.productWasSelected.emit(selectedCASCO);
      }
    } else {
      this.selectedCount = 0;
      this.setCASCOOfferToSessionStorage(selectedCASCO);
      this.productWasSelected.emit(selectedCASCO);
    }
  }

  resetCheckedRadioButton() {
    let itemId = null;
    if (this.renewalProduct) {
      const installment = this.getInstallmentByActiveTab(this.activeTab);
      installment.forEach((data) => {
        data.products.forEach((product) => {
          if (
            product.product.id === this.renewalProduct.productId &&
            product.package.id === this.renewalProduct.packageId &&
            product.installmentNumber.id ===
              this.renewalProduct.installmentNumberId
          ) {
            product.isSelected = true;
            itemId =
              product.package?.name === 'no' ? product.id : product.id + 'P';
            this.store.dispatch(
              setSelectedOfferCASCO({
                offerSelectedCASCO: this.toModelSelectedCASCO(product, itemId),
              })
            );
            this.store.dispatch(
              toggleIfSelectedOfferCASCO({ toggleIfSelectedOfferCASCO: true })
            );
          } else {
            product.isSelected = false;
          }
        });
      });
    } else {
      this.store.select(selectSelectedOfferCASCO).subscribe((rez) => {
        if (rez) {
          itemId = rez[0]?.gridItemId;
        }
      });
    }
    const input = document.getElementById(itemId) as HTMLInputElement;
    if (input) {
      input.checked = true;
    } else {
      const installment = this.getInstallmentByActiveTab(this.activeTab);
      installment.forEach((data) => {
        data.products.forEach((product) => {
          product.isSelected = false;
          const itemId =
            product.package.name === 'no' ? product.id : product.id + 'P';
          const input = document.getElementById(itemId) as HTMLInputElement;
          if (input) {
            input.checked = false;
          }
        });
      });
    }
    this.getDataService.cancelOnRequestConsultant.next(false);
  }

  activeTabRateNumber(activeTab) {
    if (activeTab === 0) {
      return '12';
    } else if (activeTab === 1) {
      return '4';
    } else if (activeTab === 2) {
      return '1';
    }
  }

  openCRPackageModal(insurerName, installmentNumber) {
    const tabRate = this.activeTabRateNumber(installmentNumber);
    const modalRef = this.modal.open(
      CascoMtplPackageModalComponent as Component,
      {
        centered: true,
        size: 'lg',
      }
    );
    modalRef.componentInstance.packageData = { insurerName, tabRate };
    modalRef.result.then(
      (result) => {},
      (_) => {}
    );
  }

  openInsuranceConditionsModal(insurer) {
    const modalRef = this.modal.open(
      InsuranceConditionsModalComponent as Component,
      {
        centered: true,
        size: 'lg',
      }
    );
    modalRef.componentInstance.showAccept = false;
    modalRef.componentInstance.inputInsurer = insurer;
    modalRef.result.then(
      (result) => {
        this.insuranceCond = result;
      },
      (_) => {}
    );
  }

  getProductPackageDiscount(): any {
    const installment = this.getInstallmentByActiveTab(this.activeTab);
    installment.forEach((data) => {
      data.products.forEach((x) => {
        const individualProduct = data.products.find(
          (p) => p.package.name === 'no'
        );
        const packageProduct = data.products.find(
          (p) => p.package.name !== 'no'
        );
       
        if (
          individualProduct &&
          individualProduct.premium &&
          packageProduct &&
          packageProduct.premium
        ) {
          data.packageDiscount =
            ((individualProduct.premium - packageProduct.premium) * 100) /
            individualProduct.premium;
          if (data.packageDiscount > 10.0) {
            data.packageDiscountSting = data.packageDiscount
              .toString()
              .substring(0, 4);
          } else {
            data.packageDiscountSting = data.packageDiscount
              .toString()
              .substring(0, 3);
          }
        }
      });
    });
  }

  private sortInstallmentListByInsurer(installmentList: any, insurer: string) {
    installmentList.forEach(() => {
      installmentList.sort((a) => {
        const ia = a.insurer;
        if (ia === insurer) {
          return -1;
        }
        return 0;
      });
    });
  }

  CampaignDetails(insurerName:any,isPackage:any,packageDiscount:string='') {   
   let product;
    let content = {
      insurer:insurerName,
      installment1:{
        cascoGold:'',
        cascoSilver:''
      },
      installment4:{
        cascoGold:'',
        cascoSilver:''
      },
      packageDiscount:packageDiscount != '' ? packageDiscount : 'no'
    }
     this.store.select(selectOfferDataCASCO).subscribe(
      CASCOData => {
        product = CASCOData;
       });

    let installment1 = product['installment1'];
    let installment4 = product['installment4'];
    let inst1  = installment1.find(el=>(el.insurer===insurerName));
    let inst4  = installment4.find(el=>(el.insurer===insurerName));

    content.installment1.cascoGold = inst1.products.find(ele=>(ele.package.name===isPackage && ele.product.name==='Casco Gold'));
    content.installment1.cascoSilver = inst1.products.find(ele=>(ele.package.name===isPackage && ele.product.name==='Casco Silver'));
    content.installment4.cascoGold = inst4.products.find(ele=>(ele.package.name===isPackage && ele.product.name==='Casco Gold'));
    content.installment4.cascoSilver = inst4.products.find(ele=>(ele.package.name===isPackage && ele.product.name==='Casco Silver'));
        
    const modalRef = this.modal.open(CampaignDetailsPopupComponent as Component, {
      size: 'lg', centered: true
    });
     modalRef.componentInstance.content = content;
  } 
}
