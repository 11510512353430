export const environment = {
  production: false,
  name: 'Asigurari Dacia',
  app: 'renault',
  env: '',
  baseApiUrl: '',
  recalculateOfferTime: 90000,
  security: {
      allowedOrigins: ''
  },  
  apiUrl: '${RESOURCE_SERVER_URL}',
  apigeeUrl: window.location.origin + '/api',
  oktaOrgURL: '${AUTHENTICATION_OKTA_ISSUER}',
  oktaClientId: '${AUTHENTICATION_OKTA_CLIENT_ID}',
  googleRecaptchaSiteKey: '${GOOGLE_RECAPTCHA_SECRET_KEY}',
  withMFA: false,
  netopiaUrl: '${NETOPIA_URL}',
};
