import { Component, Input,OnInit } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {environment} from '../../../../../../environments/environment';
import Utils from '../../../../../shared/utils/utils';
import {faWindowClose} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-campaign-details-popup',
  templateUrl: './campaign-details-popup.component.html',
  styleUrls: ['./campaign-details-popup.component.scss']
})
export class CampaignDetailsPopupComponent implements OnInit {
  appType = environment.app;
  faClose = faWindowClose;
  isMobile: boolean;
  constructor(
    private modal: NgbModal,) {
    this.isMobile = Utils.checkIfMobile();
  }

  @Input() public  content = {
    insurer:'',
    installment1:{
      cascoGold:'',
      cascoSilver:''
    },
    installment4:{
      cascoGold:'',
      cascoSilver:''
    },
    packageDiscount:''
  } 


  ngOnInit(): void {   
  }
  closeModal(){
    this.modal.dismissAll();
  }

}
