import {OfferService} from '../../../services/offers.service';
import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {
  NgbActiveModal,
  NgbCalendar,
  NgbDateStruct,
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import {UserDetailsService} from '../../../shared/services/user-details.service';
import {WarningTypeEnum} from '../../../models/mapping.model';
import {DropDownDataService} from 'src/app/services/dropDown.data.service';
import {
  AppState,
} from '../../../store';
import {Store} from '@ngrx/store';
import {ActivatedRoute, Router} from '@angular/router';
import {
  fetchOfferDataCustomerSuccess,
} from 'src/app/store/actions/offer.actions';
import {
  PersonalDataSaveModalComponent
} from '../../../shared/components/personal-data-save-modal/personal-data-save-modal.component';
import {ToastrService} from 'ngx-toastr';
import * as moment from 'moment';

@Component({
  selector: 'app-marsh-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css'],
})
export class UserDetailsComponent implements OnInit {
  @Input() fromUserDetails = false;
  warningTypesEnums = WarningTypeEnum;
  userDetails: any;
  offer: any;
  loadingPersonalData = true;
  @Input() modal: false;
  customerInitial: any;
  customer: any;
  customerAfterChange: any;
  customerId;
  model: NgbDateStruct;
  @ViewChild('NgbdDatepicker') d: NgbDateStruct;
  driverLicenseDate: NgbDateStruct;
  listCounties = [];
  listCities = [];
  mobNumberPattern = RegExp(`^(\\+4|)?(07[0-8]{1}[0-9]{1}|02[0-9]{2}|03[0-9]{2}){1}?(\\s|\\.|)?([0-9]{3}(\\s|\\.||)){2}$`);
  selectedCounty: string;
  selectedCity: string;
  today: NgbDateStruct;
  policyType: string;
  activeOffers = false;
  firstCityName: any;
  constructor(
    private calendar: NgbCalendar,
    private offerService: OfferService,
    private store: Store<AppState>,
    private modalService: NgbModal,
    public dataSource: DropDownDataService,
    private userDetailsService: UserDetailsService,
    private route: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    public activeModal: NgbActiveModal,
  ) {
    this.userDetails = this.userDetailsService.getConfig();
    this.customer = undefined;
  }

  ngOnInit() {
    this.model = this.calendar.getToday();
    this.today = this.calendar.getToday();
    this.route
      .queryParams
      .subscribe(params => {
        if (params.type) {
          this.policyType = params.type;
        }
      });
    this.initUserDetails();

  }


  initUserDetails() {
    const userId = sessionStorage.getItem('customerId');
    if (userId) {
      this.offerService.getCustomerByID(userId).subscribe(userDetails => {
        setTimeout(() => {
          this.customer = userDetails;
          this.getCustomerDetails();
        }, 1000);
      });
    }
  }

  toModel(date: NgbDateStruct): Date {
    return date
      ? new Date(Date.UTC(date.year, date.month - 1, date.day, 0, 0, 0))
      : null;
  }

  parseDate(date) {
    if (date) {
      const day = date.day < 10 ? '0' + date.day : date.day;
      const month = date.month < 10 ? '0' + date.month : date.month;
      const dateString = `${date.year}-${month}-${day}`;
      try {
        const localTime = moment(dateString).format('YYYY-MM-DD');
        return localTime + 'T00:00:00.000Z';
      } catch (ex) {
        console.log('date format exception ' + JSON.stringify(ex));
      }
    }
  }

  fromModel(date: Date): NgbDateStruct {
    return date
      ? {
        year: date.getUTCFullYear(),
        month: date.getUTCMonth() + 1,
        day: date.getUTCDate(),
      }
      : null;
  }

  get getIfThereAreActiveOffers() {
    return JSON.parse(sessionStorage.getItem('activeOffers'));
  }

  getCounties() {
    this.dataSource
      .fetchData('counties', '', null, 50, null)
      .subscribe((data) => {
        this.listCounties = data;
      });
  }

  getCities(countyId) {
    this.dataSource
      .fetchData('cities', '', countyId, 1000)
      .subscribe((data) => {
        this.listCities = data;
      });
  }

  changeCounty() {
    this.listCities = [];
    this.getCities(
      this.listCounties.filter((a) => a.text == this.selectedCounty)[0].value
    );

  }

  goBack() {
    if (this.modal) {
      this.activeModal.close();
    } else {
      this.router.navigate(['/home']);
    }
  }

  get formChanged() {
    const customerInitial = this.customerInitial;
    const customerAfterChange = this.mapCustomer();

    if (customerInitial && customerAfterChange) {
      const newCustomerValues = [];
      Object.keys(customerInitial).forEach(key => {
        Object.keys(customerAfterChange).forEach(key2 => {
          if (key === key2 && customerInitial[key] !== customerAfterChange[key2]) {
            if (key === 'city' && key2 === 'city' && customerInitial[key].id === customerAfterChange[key2].id) {
            } else if (key === 'county' && key2 === 'county' && customerInitial[key].id === customerAfterChange[key2].id) {
            } else if (key === 'driverLicenseDate' && key2 === 'driverLicenseDate' && customerInitial[key] === customerAfterChange[key2]) {
            }
            else {
              const name = key2;
              const value = customerAfterChange[key2];
              newCustomerValues.push({name, value});
            }
          }
        });
      });
      return newCustomerValues.length > 0;
    }
  }

  getFirstValueOfCity(cityValues) {
    this.firstCityName = {
      city: {
        id: cityValues.value, name: cityValues.text
      }
    };
  }


  saveData() {
    this.customerAfterChange = [];
    this.customerId = window.sessionStorage.getItem('customerId');
    this.customer = this.mapCustomer();
    this.customerAfterChange = this.customer;

    const newCustomerValues = [];
    Object.keys(this.customerInitial).forEach(key => {
      Object.keys(this.customerAfterChange).forEach(key2 => { 
        var originalValue  = (this.customerInitial[key]) ? this.customerInitial[key] : null;
        var changeValue  = (this.customerAfterChange[key2]) ? this.customerAfterChange[key2] : null;
        if (key === key2 && this.customerInitial[key] !== this.customerAfterChange[key2]) {
          if (key === 'city' && key2 === 'city' && this.customerInitial[key].id === this.customerAfterChange[key2].id) {}
          else if
          (
            key === 'driverLicenseDate' && key2 === 'driverLicenseDate' && originalValue === changeValue
            //this.customerInitial[key] === this.customerAfterChange[key2]
          ) {}
          else {
            const name = key2;
            const value = this.customerAfterChange[key2];
            newCustomerValues.push({name, value});
          }
        }
      });
    });
    if (newCustomerValues) {
      this.getFirstValueOfCity(this.listCities[0]);
      const recalculateOffer = newCustomerValues.filter(diff => diff.name === 'county' || diff.name === 'city' || diff.name === 'driverLicenseDate');
      let objToSend: any;

      newCustomerValues.forEach(x => {
        const obj = {id: +this.customerId};
        if (typeof x.value !== 'object') {
          obj[x.name] = x.value;
        } else if (x.name === 'driverLicenseDate') {
          obj[x.name] = x.value;
        }else {
          obj[x.name] = {};
          Object.keys(x.value).forEach(y => {
            obj[x.name][y] = x.value[y];
          });
        }
        objToSend = Object.assign({}, objToSend, obj);
      });
      if (objToSend?.county && !objToSend?.city) {
        console.log(objToSend, 'objs tosend');
        console.log(this.firstCityName, 'this.firstCityName');
        objToSend = Object.assign({}, objToSend, this.firstCityName);
      }
      if (recalculateOffer.length > 0 && this.getIfThereAreActiveOffers) {
        this.offerService.patchCustomer(this.customerId, objToSend.valueOf()).subscribe(result => {
          if (result) {
            this.customerInitial = this.customerAfterChange;
            const modalRef = this.modalService.open(PersonalDataSaveModalComponent as Component, {
              size: 'lg', centered: true, backdrop: 'static'
            });
            modalRef.componentInstance.fromUserDetails = this.fromUserDetails;
            
            this.store.dispatch(fetchOfferDataCustomerSuccess({offerCustomerData: result}));
          }
        });
      } else {
        this.offerService.patchCustomer(this.customerId, objToSend.valueOf()).subscribe(result => {
          if (result) {
            this.customerInitial = this.customerAfterChange;
            this.toastrService.success('Îți mulțumim pentru actualizarea datelor. Modificările au fost salvate cu succes', '', {
              timeOut: 5000,
            });
            this.store.dispatch(fetchOfferDataCustomerSuccess({offerCustomerData: result}));
          }
        });
      }
    }
  }

  getCustomerDetails() {
    // todo: use the model class, translate
    if (this.customer) {
      this.customerInitial = this.customer;
      this.userDetails.id = this.customer.id;
      this.userDetails.CNP = this.customer.uniqueIdentifier;
      this.userDetails.email = this.customer.email;
      this.userDetails.nume = this.customer.firstName;
      this.userDetails.prenume = this.customer.lastName;
      if (this.customer.driverLicenseDate) {
        this.driverLicenseDate = this.fromModel(
          new Date(this.customer.driverLicenseDate)
        );
      }
      this.userDetails.generalLocation.judet = this.customer.county?.name;
      this.userDetails.generalLocation.oras = this.customer.city?.name;
      this.userDetails.exactLocation.ap = this.customer.apartment;
      this.userDetails.exactLocation.nr = this.customer.streetNumber;
      this.userDetails.exactLocation.bl = this.customer.buildingNumber;
      this.userDetails.exactLocation.phone = this.customer.phone;
      this.userDetails.exactLocation.str = this.customer.streetName;
      if (this.userDetails.generalLocation.judet) {
        this.getCounties();
        this.getCities(this.customer.county.id);
      }
      this.loadingPersonalData = false;
    }
  }

  mapCustomer(): any {
    if (this.customer) {
      const customer = {...this.customer};
      customer.driverLicenseDate = this.parseDate(this.driverLicenseDate);
      if (this.selectedCounty) {
        const customerCounty = {...customer.county};
        customerCounty.name = this.selectedCounty;
        if (this.listCounties.filter(a => a.text.toLowerCase() == customerCounty?.name.toLowerCase())[0]) {
          customerCounty.id = this.listCounties.filter(a => a.text.toLowerCase() == customerCounty.name.toLowerCase())[0].value;
        }
        customer.county = {id: customerCounty.id, name: customerCounty.name};
      }
      if (this.selectedCity) {
        const customerCity = {...customer.city};
        customerCity.name = this.selectedCity;
        if (this.listCities.filter(a => a.text.toLowerCase() == customerCity?.name.toLowerCase())[0]) {
          customerCity.id = this.listCities.filter(a => a.text.toLowerCase() == customerCity.name.toLowerCase())[0].value;
        }
        customer.city = {id: customerCity.id, name: customerCity.name};
      } else {
        const customerCity = {...customer.city};
        if (customerCity.name && customerCity.id) {
          customer.city = {id: customerCity.id, name: customerCity.name};
        } else {
          customer.city = {id: customer.city.id, name: customer.city.name};
        }
      }
      customer.apartment = this.userDetails.exactLocation.ap;
      customer.streetNumber = this.userDetails.exactLocation.nr;
      customer.buildingNumber = this.userDetails.exactLocation.bl;
      customer.phone = this.userDetails.exactLocation.phone;
      customer.streetName = this.userDetails.exactLocation.str;
      return customer;
    } else {
      return;
    }
  }

}
